import ukusa1 from "./ukusa/ukusa-1.png";
import ukusa2 from "./ukusa/ukusa-2.png";
import ukusa3 from "./ukusa/ukusa-3.png";
import ukusa4 from "./ukusa/ukusa-4.png";
import ukusa5 from "./ukusa/ukusa-5.png";
import ukusa6 from "./ukusa/ukusa-6.png";
import ukusa7 from "./ukusa/ukusa-7.png";
import ukusa8 from "./ukusa/ukusa-8.png";
import ukusaSMM1 from "./ukusa/smm-1.jpg";
import ukusaSMM2 from "./ukusa/smm-2.jpg";

import arkitonPrint1 from "./arkiton/print-1.png";
import arkitonPrint2 from "./arkiton/print-2.png";
import arkitonPrint3 from "./arkiton/print-3.png";
import arkitonPrint4 from "./arkiton/print-4.png";
import arkitonPrint5 from "./arkiton/print-5.png";
import arkitonPrint6 from "./arkiton/print-6.png";
import arkitonPrint7 from "./arkiton/print-7.png";
import arkitonPrint8 from "./arkiton/print-8.png";
import arkitonPrint9 from "./arkiton/print-9.png";
import arkitonPrint10 from "./arkiton/print-10.png";
import arkitonPrint11 from "./arkiton/print-11.png";

import arkitonSMM from "./arkiton/smm.png";

import capNxtBrand1 from "./capnxt/brand-1.png";
import capNxtBrand2 from "./capnxt/brand-2.png";
import capNxtBrand3 from "./capnxt/brand-3.png";
import capNxtBrand4 from "./capnxt/brand-4.png";
import capNxtBrand5 from "./capnxt/brand-5.png";
import capNxtBrand6 from "./capnxt/brand-6.png";
import capNxtBrand7 from "./capnxt/brand-7.png";

import capNxtWebsite1 from "./capnxt/website.png";
import capNxtWebsite2 from "./capnxt/website-2.png";
import capNxtWebsite3 from "./capnxt/website-3.png";

import capNxtSMM from "./capnxt/smm-1.png";

import energonWebsite1 from "./energon/website-1.jpeg";
import energonWebsite2 from "./energon/website-2.jpeg";
import energonWebsite3 from "./energon/website-3.jpeg";
import energonWebsite4 from "./energon/website-4.jpeg";
import energonWebsite5 from "./energon/website-5.jpeg";
import energonWebsite6 from "./energon/website-6.jpeg";
import energonWebsite7 from "./energon/website-7.jpeg";

import ennessarBrand1 from "./ennessar/brand-1.png";
import ennessarBrand2 from "./ennessar/brand-2.png";
import ennessarBrand3 from "./ennessar/brand-3.png";
import ennessarBrand4 from "./ennessar/brand-4.png";
import ennessarBrand5 from "./ennessar/brand-5.png";
import ennessarBrand6 from "./ennessar/brand-6.png";
import ennessarBrand7 from "./ennessar/brand-7.png";
import ennessarBrand8 from "./ennessar/brand-8.png";

import ennessarStationary1 from "./ennessar/stationary-1.png";
import ennessarStationary2 from "./ennessar/stationary-2.png";
import ennessarStationary3 from "./ennessar/stationary-3.png";

import ennessarWebsite1 from "./ennessar/website-1.png";
import ennessarWebsite2 from "./ennessar/website-2.png";

import firstFleetBrand1 from "./first-fleet/brand-1.jpeg";
import firstFleetBrand2 from "./first-fleet/brand-2.jpeg";
import firstFleetBrand4 from "./first-fleet/brand-4.jpeg";
import firstFleetBrand5 from "./first-fleet/brand-5.jpeg";
import firstFleetBrand6 from "./first-fleet/brand-6.jpeg";
import firstFleetBrand7 from "./first-fleet/brand-7.jpeg";
import firstFleetBrand8 from "./first-fleet/brand-8.jpeg";
import firstFleetBrand9 from "./first-fleet/brand-9.jpeg";
import firstFleetBrand10 from "./first-fleet/brand-10.jpeg";
import firstFleetBrand11 from "./first-fleet/brand-11.jpeg";

import isgPrint1 from "./indian-silk/print-1.jpeg";
import isgPrint2 from "./indian-silk/print-2.jpeg";
import isgPrint3 from "./indian-silk/print-3.jpeg";
import isgPrint4 from "./indian-silk/print-4.jpeg";
import isgPrint5 from "./indian-silk/print-5.jpeg";
import isgPrint6 from "./indian-silk/print-6.jpeg";

import honeyPoster2 from "./only-honey/honey-poster-2.jpeg";
import honeyPoster3 from "./only-honey/honey-poster-3.jpeg";
import honeyPoster4 from "./only-honey/honey-poster-4.jpeg";
import honeyPoster5 from "./only-honey/honey-poster-5.jpeg";

import honeySMM from "./only-honey/smm-1.png";

import avenuesAdCampaign1 from "./prakruti-avenues/ad-campaign-1.png";
import avenuesAdCampaign2 from "./prakruti-avenues/ad-campaign-2.png";
import avenuesAdCampaign3 from "./prakruti-avenues/ad-campaign-3.png";
import avenuesAdCampaign4 from "./prakruti-avenues/ad-campaign-4.png";
import avenuesAdCampaign5 from "./prakruti-avenues/ad-campaign-5.png";

import avenuesLeadCampaign1 from "./prakruti-avenues/lead-campaign-1.png";
import avenuesLeadCampaign2 from "./prakruti-avenues/lead-campaign-2.png";

import avenuesSMM from "./prakruti-avenues/smm-1.png";

import avenuesWebsite1 from "./prakruti-avenues/website-1.png";
import avenuesWebsite2 from "./prakruti-avenues/website-2.png";

import prakrutiSchoolAd1 from "./prakruti-school/ad-campaign-1.jpeg";
import prakrutiSchoolAd2 from "./prakruti-school/ad-campaign-2.jpeg";
import prakrutiSchoolAd3 from "./prakruti-school/ad-campaign-3.jpeg";
import prakrutiSchoolAd4 from "./prakruti-school/ad-campaign-4.jpeg";
import prakrutiSchoolAd6 from "./prakruti-school/ad-campaign-6.jpeg";
import prakrutiSchoolAd7 from "./prakruti-school/ad-campaign-7.jpeg";

import prakrutiSchoolWebsite1 from "./prakruti-school/website-1.png";
import prakrutiSchoolWebsite2 from "./prakruti-school/website-2.png";

import prakrutiSchoolStationary1 from "./prakruti-school/stationary-1.png";
import prakrutiSchoolStationary2 from "./prakruti-school/stationary-2.png";
import prakrutiSchoolStationary3 from "./prakruti-school/stationary-3.png";
import prakrutiSchoolStationary4 from "./prakruti-school/stationary-4.png";
import prakrutiSchoolStationary5 from "./prakruti-school/stationary-5.png";

import prakrutiSchoolSMM from "./prakruti-school/smm-1.png";

import stockerBrand1 from "./stocker/brand-1.jpg";
import stockerBrand2 from "./stocker/brand-2.jpg";
import stockerBrand3 from "./stocker/brand-3.jpg";

import stockerWebsite1 from "./stocker/website-1.jpg";
import stockerWebsite2 from "./stocker/website-2.jpg";

import triveniBrand1 from "./triveni/brand-1.png";
import triveniBrand2 from "./triveni/brand-2.png";
import triveniBrand3 from "./triveni/brand-3.png";
import triveniBrand4 from "./triveni/brand-4.png";
import triveniBrand5 from "./triveni/brand-5.png";
import triveniBrand6 from "./triveni/brand-6.png";
import triveniBrand7 from "./triveni/brand-7.png";
import triveniBrand8 from "./triveni/brand-8.png";

import reachIcon from "../../icons/reach.svg";
import linksClickIcon from "../../icons/link-clicks.svg";
import postsEngIcon from "../../icons/post-engagement.svg";
import impressionsIcon from "../../icons/impressions-icon.svg";

const caseStudies = {
  ukusa: {
    name: "Ukusa",
    images: {
      branding: [ukusa1, ukusa2, ukusa3],
      packing: [ukusa4, ukusa5, ukusa6, ukusa7, ukusa8],
      SMM: [ukusaSMM1, ukusaSMM2],
    },
    sections: {
      branding: {
        type: "Branding",
        content: [
          `Ukusa is a word in Zulu for "Dawn" it also means flavor in Croatian. At Ukusa, Coffee is made with passion, food is made with warmth and desserts are made with love.

          `,
          `We started the journey of branding with a research on brand perception. Based on the research , we created the logo. A different set of options were provided for the brand. Based on their feedback, a logo was finalised. below are workings of the brand`,
        ],
      },
      packing: {
        type: "Packing",
        content: [
          `Creating captivating packaging designs for a cafe is essential
          to leave a lasting impression on customers. A harmonious blend
          of aesthetics and functionality is key. We incorporated Ukusa's
          branding elements, such as logos and colour schemes, while 
          maintaining a balance between simplicity and creativity, 
          into the packaging designs. We also integrated eco-friendly 
          materials and practical designs to enhance convenience. 
          Our ultimate goal - packaging should reflect Ukusa's 
          unique identity and enhance the overall customer experience.`,
        ],
      },
      SMM: {
        type: "SMM",
        content: [
          `Ukusa decided to harness the power of social 
          media to boost its success! Vaikhari engages 
          the audience with delectable food photos, 
          shares behind-the-scenes stories, and promotes 
          exclusive offers. We created a strong online 
          presence by posting regularly on platforms 
          like Facebook and Instagram. 
         `,
          `Constant interaction with customers, responding to 
          their queries, and building a loyal community leaves 
          the followers happy. We leverage social media marketing 
          to drive foot traffic, increase brand awareness, and 
          cultivate a thriving online community around Ukusa`,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "226.1K",
      },
      {
        icon: linksClickIcon,
        name: "Link Clicks",
        value: "10,284",
      },
      {
        icon: postsEngIcon,
        name: "Post Engagement",
        value: "113.7K",
      },
    ],
  },
  ennessar: {
    name: "Ennessar",
    images: {
      branding: [
        ennessarBrand1,
        ennessarBrand2,
        ennessarBrand3,
        ennessarBrand4,
        ennessarBrand5,
        ennessarBrand6,
        ennessarBrand7,
        ennessarBrand8,
      ],
      website: [ennessarWebsite1, ennessarWebsite2],
      SMM: [ennessarStationary1, ennessarStationary2, ennessarStationary3],
    },
    sections: {
      branding: {
        type: "Logo",
        content: [
          `Logo design for Ennessaar required capturing the essence of 
          strength, heritage, and innovation. Incorporating symbolic elements 
          like arrows, along with bold typography, can convey reliability and progress. 
          Vibrant colors reflecting power and growth add a captivating touch. `,
        ],
      },
      website: {
        type: "Website",
        content: [
          `Designing a website for a construction company requires a seamless 
          blend of functionality and aesthetics. Incorporating vibrant visuals, 
          intuitive navigation, and support is crucial. Highlighting project 
          portfolios and client testimonials can inspire trust. Prioritizing 
          mobile responsiveness and fast loading speeds ensures a user-friendly 
          experience, while emphasizing local expertise helps establish credibility.`,
        ],
      },
      SMM: {
        type: "Business Stationery",
        content: [
          `Our team of skilled designers specializes in crafting professional 
          and captivating business stationery. With a keen eye for detail, 
          we create impressive business cards and letterheads that reflect 
          the essence of your brand. Stand out from the crowd with our bespoke 
          designs that leave a lasting impression on your clients.`,
        ],
      },
    },
  },
  firstfleet: {
    name: "First Fleet",
    images: {
      branding: [
        firstFleetBrand1,
        firstFleetBrand7,
        firstFleetBrand5,
        firstFleetBrand2,
        firstFleetBrand4,
        firstFleetBrand6,
        firstFleetBrand8,
        firstFleetBrand9,
        firstFleetBrand10,
        firstFleetBrand11,
      ],
    },
    sections: {
      branding: {
        type: "Branding",
        content: [
          `Designing a logo for a logistics company requires a blend of creativity and practicality. 
          The logo should convey efficiency, reliability, and professionalism. We decided to use bold, 
          clean lines to symbolize movement and organization. Incorporate elements like arrows or 
          interconnected shapes to represent the smooth flow of goods. Choose a colour palette 
          that exudes trust and stability, such as blues and greys. Finally, aim for a distinctive 
          design that stands out in a crowded marketplace.`,
        ],
      },
    },
  },
  arkiton: {
    name: "Arkiton",
    images: {
      printMedia: [
        arkitonPrint1,
        arkitonPrint2,
        arkitonPrint3,
        arkitonPrint4,
        arkitonPrint5,
        arkitonPrint6,
        arkitonPrint7,
        arkitonPrint8,
        arkitonPrint9,
        arkitonPrint10,
        arkitonPrint11,
      ],
      SMM: [arkitonSMM],
    },
    sections: {
      printMedia: {
        type: "Print Media",
        content: [
          `With captivating posters and larger-than-life hoardings, 
          we showcase the seamless fusion of artistry and functionality. 
          Experience the transformative power of our designs as we create 
          spaces that elevate your lifestyle. We let our posters 
          and hoardings do the talking.`,
        ],
      },
      SMM: {
        type: "SMM",
        content: [
          `Social media marketing is all about selling your product/ service to 
          customers in the most creative and time-efficient way. 
          For Arkiton, our social media strategy included a blend of 
          stunning visuals, appealing content, and thoughtful storytelling. 
          With targeted ads and consistent interaction, we cultivated a 
          loyal audience for business growth.`,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "821.7K",
      },
    ],
  },
  onlyhoney: {
    name: "Only Honey",
    images: {
      SMM: [honeyPoster3, honeyPoster5, honeyPoster4, honeyPoster2, honeySMM],
    },
    sections: {
      // branding: {
      //   type: "Branding",
      //   content: [
      //     `Lorem ipsum dolor sit amet consectetur adipisicing elit.
      //   Saepe adipisci necessitatibus asperiores cum, unde porro?
      //   Totam sed, saepe earum expedita aperiam quis ad voluptas
      //   fuga sit doloremque vitae adipisci ducimus.`,
      //     `Lorem ipsum dolor sit amet consectetur adipisicing elit.
      //   Saepe adipisci necessitatibus asperiores cum, unde porro?
      //   Totam sed, saepe earum expedita aperiam quis ad voluptas
      //   fuga sit doloremque vitae adipisci ducimus.`,
      //   ],
      // },
      SMM: {
        type: "SMM",
        content: [
          `Only Honey harnessed the power of social media to 
          increase the reach of their brand. Our strategy 
          included captivating visuals and engaging content,
          leveraging platforms like Facebook, Instagram,
          and Google to reach a buzzing audience. 
          By sharing mouthwatering recipes, health benefits, 
          and eco-friendly practices we created a hive of loyal followers`,
          `We also collaborate with influencers who align with 
          their brand values, amplifying the brand message
          to a wider community. By responding promptly to 
          customer queries and reviews, and cultivating a 
          strong online presence we let social media be the 
          beehive where Only Honey thrives and spreads its 
          irresistible sweetness.`,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "147.8K",
      },
    ],
  },
  prakrutiavenues: {
    name: "Prakruti Avenues",
    images: {
      adCampaign: [avenuesAdCampaign1, avenuesAdCampaign2, avenuesAdCampaign3],
      website: [avenuesWebsite1, avenuesWebsite2],
      leadCampaign: [avenuesAdCampaign4, avenuesAdCampaign5],
      SMM: [avenuesSMM, avenuesLeadCampaign2, avenuesLeadCampaign1],
    },
    sections: {
      adCampaign: {
        type: "Advertisement Campaign",
        content: [
          `Challenge: Prakruti Avenues sought to boost sales and expand its customer base. Facing fierce competition, they needed an effective advertising strategy to reach potential buyers.  `,
          `Solution: Implementing targeted Google Ads campaigns proved instrumental. Leveraging precise audience targeting, compelling ad copy, and strategic bidding, the company maximized visibility and generated high-quality leads.  `,
          `Results: The campaign yielded a 45% increase in website traffic, resulting in a 20% rise in property inquiries. With improved conversions and a stronger market presence, the company experienced significant revenue growth, solidifying their position as a leader in the real estate industry. `,
        ],
      },
      website: {
        type: "Website",
        content: [
          `Designing a website for a market-leading real estate company requires a strategic approach. We craft an intuitive user experience with a clean, modern design that showcases their extensive property listings..`,
          `The design should showcase stunning property imagery, intuitive navigation, and responsive layouts to engage visitors. Emphasizing the company's expertise, user-friendly search tools, and seamless contact options will help solidify their position as industry frontrunners. `,
        ],
      },
      leadCampaign: {
        type: "Lead Generation Campaigns",
        content: [
          `Our team developed a targeted lead generation campaign for Prakruti Avenues, focusing on digital channels. We crafted compelling content, optimized their website for lead capture, launched lead generation campaigns, and implemented effective advertising strategies.`,
          `The lead generation campaign yielded exceptional outcomes. Prakruti received a significant increase in website traffic and received a steady stream of qualified leads. The conversion rate improved, leading to a notable rise in sales and revenue. `,
          `By implementing a tailored lead generation campaign, Prakruti successfully established its presence, attracted qualified leads, and achieved remarkable growth in sales.`,
        ],
      },
      SMM: {
        type: "SMM",
        content: [
          `Our social media marketing and management services offered Prakruti a powerful online presence. Through strategic planning, content creation, and targeted campaigns, we enhance brand visibility, engage with potential buyers, and drive traffic to property listings. `,
          `Our expertise in platforms like Facebook, Instagram, and Youtube ensures maximum reach and engagement.  `,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "64.9K",
      },
      {
        icon: linksClickIcon,
        name: "Link Clicks",
        value: "4.4K",
      },
      {
        icon: impressionsIcon,
        name: "Impressions",
        value: "262.7K",
      },
    ],
  },
  triveni: {
    name: "Triveni",
    images: {
      branding: [
        triveniBrand1,
        triveniBrand2,
        triveniBrand3,
        triveniBrand4,
        triveniBrand5,
        triveniBrand6,
        triveniBrand7,
        triveniBrand8,
      ],
    },
    sections: {
      branding: {
        type: "Branding",
        content: [
          `Triveni sought our expertise to develop a logo that would effectively 
          communicate their brand values and appeal to their target customers. 
          The design team conducted in-depth research on visual aesthetics 
          to create a logo that would be both authentic and captivating. 
          They incorporated elements such as vibrant colors and intricate 
          design that conveys the essence of the brand and its offerings. `,
        ],
      },
    },
  },
  energon: {
    name: "Energon",
    images: {
      website: [
        energonWebsite5,
        energonWebsite6,
        energonWebsite7,
        energonWebsite1,
        energonWebsite2,
        energonWebsite3,
        energonWebsite4,
      ],
    },
    sections: {
      website: {
        type: "Website",
        content: [
          `Designing a website for Energon required a thoughtful approach 
          that showcases their expertise while engaging visitors. 
          The site features a clean layout, vibrant visuals, and 
          intuitive navigation. To emphasize sustainability, renewable 
          solutions, and energy efficiency we used compelling content. `,
          `The website has a dynamic interface, highlighting the company's 
          expertise in energy solutions. We thought it should incorporate 
          a visually captivating design, showcasing the vital role the 
          company plays in powering the petrochemical sector.`,
        ],
      },
    },
  },
  prakrutischool: {
    name: "Prakruthi School",
    images: {
      stationaryDesign: [
        prakrutiSchoolStationary1,
        prakrutiSchoolStationary2,
        prakrutiSchoolStationary3,
        prakrutiSchoolStationary4,
        prakrutiSchoolStationary5,
      ],
      website: [prakrutiSchoolWebsite1, prakrutiSchoolWebsite2],
      adCampaign: [
        prakrutiSchoolAd1,
        prakrutiSchoolAd2,
        prakrutiSchoolAd3,
        prakrutiSchoolAd4,
        prakrutiSchoolAd6,
        prakrutiSchoolAd7,
      ],
      SMM: [prakrutiSchoolSMM],
    },
    sections: {
      stationaryDesign: {
        type: "School Stationery Designs",
        content: [
          `Designing notebook covers for a school requires a creative perspective. 
          We believe vibrant colours and school motifs can enhance aesthetics. 
          Our choice of durable materials and protective coatings ensures longevity. `,
          `Customizing covers with the school logo fosters a sense of pride and unity. 
          Balancing functionality and visual appeal creates a memorable accessory for students. 
          Remember, a well-designed notebook cover reflects the spirit of learning, 
          sparking enthusiasm in young minds.`,
        ],
      },
      website: {
        type: "Website",
        content: [
          `Designing a school website requires careful consideration of 
          functionality and aesthetics. We designed a user-friendly layout, 
          with clear navigation and organized content sections. 
          We also added important features including event calendars, 
          news updates, and a contact information form. A responsive design 
          is crucial, ensuring compatibility across devices.
          We believe the visual elements should reflect the school's branding 
          and foster a sense of community.`,
        ],
      },

      adCampaign: {
        type: "Online Ad Campaign",
        content: [
          `Creating and running effective online ad campaigns for a 
          school requires strategic planning and targeted messaging. 
          We started with identifying the target audience and designing 
          compelling ads highlighting the school's unique features and benefits. `,
          `We used platforms like Google Ads, and social media to reach the 
          desired audience. Constant monitoring of campaign performance, 
          tweaks to ads as needed, and analysis of data to optimize results 
          were done.`,
        ],
      },

      SMM: {
        type: "SMM",
        content: [
          `Effective social media marketing is essential for 
          schools to engage with their target audience and build a 
          strong online presence. By utilizing social platforms schools 
          can share important updates, showcase achievements, and foster 
          community involvement. Creative content, such as photos, 
          videos, and student activities, helps in generating interest and increasing enrollment.`,
          `Regular posting, responding to comments, and leveraging 
          hashtags and trends also enhance visibility. Social media
          marketing enables schools to connect with students, parents, 
          and alumni, ultimately building a vibrant online community that
          reflects the institution's values and accomplishments.`,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "201.8K",
      },
    ],
  },
  capnxt: {
    name: "CapNxt Global",
    images: {
      branding: [
        capNxtBrand1,
        capNxtBrand2,
        capNxtBrand3,
        capNxtBrand4,
        capNxtBrand5,
        capNxtBrand6,
        capNxtBrand7,
      ],
      website: [capNxtWebsite1, capNxtWebsite2, capNxtWebsite3],
      SMM: [capNxtSMM],
    },
    sections: {
      branding: {
        type: "Branding",
        content: [
          `Designing a logo for an IT and AI company requires a balance between innovation and professionalism. 
          We designed a logo with a clean and modern aesthetic combined with sleek typography which can signify sophistication and expertise.
          `,
          `Using abstract shapes or symbols related to the brand name added a unique touch. 
          Colours like blue and green evoke trust, growth and intelligence.
          Ultimately, our well-crafted logo should captivate viewers, instilling confidence in their capabilities.`,
        ],
      },
      website: {
        type: "Website",
        content: [
          `Designing a website for an IT company requires a seamless blend of innovation and functionality. With crisp layouts, intuitive navigation, and a sleek aesthetic, the website showcases the company's expertise in cutting-edge technologies. Attention is given to responsive design, ensuring optimal user experience across devices. `,
          `Engaging content highlights the company's solutions, from AI-driven software to IT consulting. Captivating visuals and compelling calls-to-action create a compelling online presence, establishing the company as a leader in the tech industry`,
        ],
      },
      SMM: {
        type: "SMM",
        content: [
          `In the fast-paced world of IT and AI, effective social media marketing is crucial for success. As experts in this field, we understand the importance of crafting engaging content to captivate the brand’s target audience.`,
          `By leveraging platforms such as Facebook, Instagram, and LinkedIn, we built a strong online presence for your company. With strategic planning, compelling visuals, and relevant industry insights, we drive traffic, generate leads, and foster meaningful connections, ultimately boosting the brand's visibility and growth.`,
        ],
      },
    },
    metrics: [
      {
        icon: reachIcon,
        name: "Reach",
        value: "46.9K",
      },
    ],
  },
  stocker: {
    name: "Stocker",
    images: {
      branding: [stockerBrand1, stockerBrand2, stockerBrand3],
      website: [stockerWebsite1, stockerWebsite2],
    },
    sections: {
      branding: {
        type: "Branding",
        content: [
          `Designing a logo for The Stocker required strategic creativity. We believe that the logo should reflect professionalism and trust while capturing the essence of the financial industry. Incorporating elements like charts, arrows can symbolize market expertise.`,
          `A color palette of blues and grays exudes stability and confidence. Clean typography with a modern twist ensures readability. An eye-catching logo for a trading company is one that conveys reliability and proficiency, enticing investors with its visual appeal. `,
        ],
      },
      website: {
        type: "Website",
        content: [
          `Creating a website for The Stocker required a strategic approach. A clean and intuitive layout with easy navigation is essential to guide visitors towards valuable information. We incorporated eye-catching visuals and relevant charts to enhance user engagement. `,
          `The site should offer clear subscription options and a secure payment gateway. Responsive design ensures compatibility across devices. Regularly updated content, a blog section, and social media integration contribute to establishing credibility and fostering client trust in the company's trading expertise. `,
        ],
      },
    },
  },
  isg: {
    name: "Indian Silk Gallery",
    images: {
      SMM: [isgPrint1, isgPrint2],
      printMedia: [isgPrint3, isgPrint4, isgPrint5, isgPrint6],
    },
    sections: {
      SMM: {
        type: "Social Media Marketing",
        content: [
          `Effective social media marketing and account management are crucial for Indian Silk Gallery as they organise textile expos. With the ability to reach a wide audience, social media platforms serve as powerful tools for promoting their events, showcasing the exclusivity of the exhibitors, and engaging potential attendees.`,
          `By leveraging strategic and eye-catching content, engagement management, and targeted advertising, Indian Silk Gallery can drive brand awareness, generate leads, and foster connections within the textile industry. Consistent and authentic engagement across platforms like Facebook, Instagram, Google and YouTube will enhance the brand’s online presence and ultimately contribute to the success of its expos.`,
        ],
      },
      printMedia: {
        type: "Print Media",
        content: [
          `Our design team specializes in crafting captivating visual experiences for textile expo customers. Through meticulous attention to detail, we create posters and newspaper ads that leave a lasting impression on the audience. `,
          `With a carefully curated blend of vibrant colours, engaging typography, and innovative layouts, our designs capture the essence of a brand. Whether it's promoting upcoming events, showcasing exhibitors’ exclusive products, or highlighting exclusive collections, our designs are tailored to amplify the impact of your textile expos and attract enthusiastic buyers.`,
        ],
      },
    },
  },
};

export default caseStudies;

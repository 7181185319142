import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Work from "./pages/our-work/Work";
import Services from "./pages/services/Services";
import AOS from "aos";
import topIcon from "./assets/icons/go-to-top.svg";
import "aos/dist/aos.css";
import { useEffect } from "react";
import CaseStudy from "./pages/case-studies/CaseStudy";
function App() {
  useEffect(() => {
    AOS.init();
    document.addEventListener('scroll',()=>{
      const scrollTopBtn = document.querySelector('.scroll-to-top');
      if(window.document.scrollingElement.scrollTop > (window.document.scrollingElement.scrollHeight / 10)){
        scrollTopBtn.classList.remove('hide');
      }else{
        scrollTopBtn.classList.add('hide');
      }
    })
    // AOS.refresh();
    // const handleTouchMove = event => {
    //   if (event.scale !== 1) {
    //     event.preventDefault();
    //   }
    // };

    // document.addEventListener('touchmove', handleTouchMove, { passive: false });

    // return () => {
    //   document.removeEventListener('touchmove', handleTouchMove);
    // };
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="/work" element={<Work />} />
          <Route path="/case-study" element={<CaseStudy/>}/>
          <Route
            path="/*"
            element={
              <>
                <h1>Page Not Found !</h1>
              </>
            }
          />
        </Routes>
      </div>
      <div className="scroll-to-top hide" onClick={()=>{
        window.scrollTo(0,0);
      }}>
        <img src={topIcon} alt=""/>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;

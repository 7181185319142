import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import LogoWhite from "../../assets/icons/logo-white.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./case-study.scss";
import { useEffect, useState } from "react";
import caseStudies from "../../assets/images/case-studies";
import Navbar from "../../components/navbar/Navbar";

export default function CaseStudy() {
  gsap.registerPlugin(ScrollTrigger);
  const { state } = useLocation();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 768 ? true : false
  );
  const scrollTriggers = () => {
    // const totalLength = caseStudies[state.case].images.length;
    Object.keys(caseStudies[state.case].sections).forEach((key, i) => {
      console.log(key);
      var lastNo = caseStudies[state.case].images[key].length - 1;
      gsap.to(`.section.${key}`, {
        scrollTrigger: {
          trigger: `#case-image-${key}-${lastNo}`,
          start: "bottom bottom",
          end: "bottom top",
          scrub: true,
        },
        opacity: 0,
        translateY: "-120%",
      });
    });
  };
  const desktopActions = () => {
    const baseTime = 1000;
    var images = Array.from(document.querySelectorAll(".image"))
      .slice(0, 3)
      .reverse();
    setTimeout(() => {
      images.forEach((img, i) => {
        setTimeout(() => {
          img.classList.add("active");
        }, i * 200);
      });
    }, baseTime - 500);
    setTimeout(() => {
      images.forEach((img, i) => {
        setTimeout(() => {
          img.classList.add("final");
        }, i * 200);
      });
    }, baseTime * 1.5);
    setTimeout(() => {
      document.querySelector(".black-bg").classList.remove("active");
    }, baseTime * 2);
    setTimeout(() => {
      scrollTriggers();
    }, baseTime * 2 + 500);
  };
  const mobileActions = () => {
    setTimeout(() => {
      document.querySelectorAll(".mobile .image").forEach((el) => {
        gsap.to(el, {
          scale: 1.1,
          translateY : '-5%',
          duration : 0.5,
          scrollTrigger: {
            start: "center bottom",
            end: "top center",
            trigger: el,
            scrub: true,
            markers : false  
          },
        });
      });
    }, 1500);
    console.log("on mobile");
  };
  window.addEventListener("resize", () => {
    if (window.innerWidth > 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });
  useEffect(() => {
    if (isMobile) {
      desktopActions();
    } else {
      mobileActions();
    }
  }, [isMobile]);
  return (
    <>
      {/* <Navbar /> */}
      <div className="case-study">
        <div className="container desktop">
          <div className="black-bg active"></div>
          <div className="col content">
            <div className="logo">
              <Link to="/">
                <img src={LogoWhite} alt="" />
              </Link>
            </div>
            <div className="sections">
              {Object.keys(caseStudies[state.case].sections).map((key, i) => {
                var obj = caseStudies[state.case].sections;
                var length = Object.keys(obj).length;
                return (
                  <div
                    className={`section ${key}`}
                    style={{ zIndex: `${length - i}` }}
                    key={`section-${key}-${i}`}
                  >
                    <div className="tag">{obj[key].type}</div>
                    <div className="title">{caseStudies[state.case].name}</div>
                    <div className="data">
                      {obj[key].content.map((data, i) => {
                        return <p key={`data-${i}`}>{data}</p>;
                      })}
                    </div>
                    {caseStudies[state.case].metrics && key === "SMM" ? (
                      <div className="metrics">
                        {caseStudies[state.case].metrics.map((rec, i) => {
                          return (
                            <div className="metric" key={`metric-${i}`}>
                              <div className="metric-img">
                                <img src={rec.icon} alt="" />
                              </div>
                              <div className="content">
                                <div className="title">
                                  <p>{rec.name}</p>
                                </div>
                                <div className="value">
                                  <p>{rec.value}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col images">
            {Object.keys(caseStudies[state.case].images).map((rec, i) => {
              return caseStudies[state.case].images[rec].map((img, i) => {
                return (
                  <div
                    className="image"
                    key={`case-image-${rec}-${i}`}
                    id={`case-image-${rec}-${i}`}
                  >
                    <img src={img} alt="" />
                  </div>
                );
              });
            })}
          </div>
        </div>
        <div className="container mobile">
          <Navbar/>
          {Object.keys(caseStudies[state.case].sections).map((key, i) => {
            const obj = caseStudies[state.case].sections;
            return (
              <div className="section" key={`section-${i}-${key}`}>
                <div className="title">{caseStudies[state.case].name}</div>
                <div className="tag">{obj[key].type}</div>
                <div className="data">
                  {obj[key].content.map((data, i) => {
                    return <p key={`data-${i}`}>{data}</p>;
                  })}
                </div>
                {caseStudies[state.case].metrics && key === "SMM" ? (
                  <div className="metrics">
                    {caseStudies[state.case].metrics.map((rec, i) => {
                      return (
                        <div className="metric" key={`metric-${i}`}>
                          <div className="metric-img">
                            <img src={rec.icon} alt="" />
                          </div>
                          <div className="content">
                            <div className="title">
                              <p>{rec.name}</p>
                            </div>
                            <div className="value">
                              <p>{rec.value}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
                <div className="images">
                  {caseStudies[state.case].images[key].map((img, i) => {
                    return (
                      <div
                        className="image"
                        key={`case-image-${key}-${i}`}
                        id={`case-image-${key}-${i}`}
                      >
                        <img src={img} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

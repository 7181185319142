import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoWhite from "../../assets/icons/logo-white.svg";
import LogoBlack from "../../assets/icons/logo-black.svg";
import fbIcon from "../../assets/icons/fb.svg";
import igIcon from "../../assets/icons/ig.svg";
import ytIcon from "../../assets/icons/yt.svg";
import inIcon from "../../assets/icons/in.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./navbar.scss";
export default function Navbar() {
  const location = useLocation();
  const [baseColor, setBaseColor] = useState("white");
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/") {
      setBaseColor("white");
    } else if (location.pathname === "/work") {
      setBaseColor("white");
    } else if (location.pathname === "/services") {
      console.log(baseColor);
      setBaseColor("black");
    }
  }, [location]);
  const hamMenuOpen = (el) => {
    var menu = document.querySelector(".ham-menu");
    var sideBar = document.querySelector(".side-bar-menu");

    sideBar.classList.toggle("active");
    menu.classList.toggle("active");

    if (sideBar.classList.contains("active")) {
      document.body.style = "height : 100vh; overflow : hidden";
    } else {
      document.body.style = "";
    }
  };

  const [showLogo, setShowLogo] = useState(false);

  setTimeout(() => {
    setShowLogo(true);
  }, 1100);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className={`navbar ${baseColor} desktop`}>
        {/* <div className="contact-container">
          <div className="contact">
            <p className={baseColor}>
              <a
                href="mailto:hello@vaikahridigital.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@vaikahridigital.com
              </a>
            </p>
            <p className={baseColor}>
              <a href="tel:918803755555">+91 88037 55555</a>
            </p>
          </div>
        </div> */}
        <div className="nav">
          <div className="logo">
            <Link to="/">
              {sessionStorage.getItem("animation") ? (
                <>
                  {" "}
                  <img
                    src={baseColor === "black" ? LogoBlack : LogoWhite}
                    alt=""
                  />{" "}
                </>
              ) : (
                <>
                  {showLogo && (
                    <img
                      src={baseColor === "black" ? LogoBlack : LogoWhite}
                      alt=""
                    />
                  )}
                </>
              )}
            </Link>
          </div>
          <div className="nav-links">
            <ul>
              <li
                className={baseColor}
                // data-aos="zoom-in"
                // data-aos-duration="500"
                // data-aos-easing="linear"
              >
                <Link to="/">Home</Link>
              </li>
              <li
                className={baseColor}
                // data-aos="zoom-in"
                // data-aos-duration="500"
                // data-aos-easing="linear"
              >
                <Link to="/work">Our Work</Link>
              </li>
              <li
                className={baseColor}
                // data-aos="zoom-in"
                // data-aos-duration="500"
                // data-aos-easing="linear"
              >
                <Link to="/services">Services</Link>
              </li>
              <li
                className={baseColor}
                // data-aos="zoom-in"
                // data-aos-duration="500"
                // data-aos-easing="linear"
              >
                <Link
                  onClick={() => {
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`navbar ${baseColor} mobile`}>
        <div className="side-bar-menu">
          <ul>
            <li>
              <Link to="/" onClick={hamMenuOpen}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/work" onClick={hamMenuOpen}>
                Our Work
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={hamMenuOpen}>
                Services
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  hamMenuOpen();
                  window.scrollTo(0, document.body.scrollHeight);
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <div className="social-icons">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/vaikharidigital/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/vaikharidigital/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/vaikhari/?originalSubdomain=in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={inIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCtqaLcRwrt6jrD4pYj35UKg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ytIcon} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="ham-menu" onClick={hamMenuOpen}>
          <div className={`ham-icon ${baseColor}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={baseColor === "black" ? LogoBlack : LogoWhite} alt="" />
          </Link>
        </div>

        <div className={`contact ${baseColor}`}>
          <p>
            <a href="mailto:hello@vaikharidigital.com">
              hello@vaikahridigital.com
            </a>
          </p>
          <p>
            <a href="tel:918803755555">+91 88037 55555</a>
          </p>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import DownArrowWhite from "../../assets/icons/down-arrow-white.svg";
import rightArrow from "../../assets/images/right-arrow.png";
import Footer from "../../components/footer/Footer";
import headerVideo from "../../assets/videos/home-head.mp4";
import Ennessar from "../../assets/images/ennessar-logo.png";
import FirstFleet from "../../assets/images/first-fleet-logo.png";
import Arkiton from "../../assets/images/arkiton-logo.png";
import CafeAndProducts from "../../assets/icons/F&B (Cafe & Products).svg";
import construction from "../../assets/icons/Construction.svg";
import RealState from "../../assets/icons/Real Estate.svg";
import Education from "../../assets/icons/Education.svg";
import Technology from "../../assets/icons/Techology.svg";
import Textile from "../../assets/icons/Textile.svg";
import HealthCare from "../../assets/icons/Health Care.svg";
import Transport from "../../assets/icons/Transport.svg";
import lifeStyle from "../../assets/icons/Life Style.svg";
import professionalService from "../../assets/icons/Professional Services.svg";
import Trading from "../../assets/icons/Trading.svg";
import sliderArkiton from "../../assets/images/slider-arkiton.jpg";
import sliderCapnxt from "../../assets/images/slider-capnxt.jpg";
import slideEnnerssar from "../../assets/images/slider-ennerssar.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideUkusa from "../../assets/images/slider-ukusa.jpg";
import slideOnlyHoney from "../../assets/images/slider-only-honey.jpg";
import Navbar from "../../components/navbar/Navbar";
import logoBlack from "../../assets/icons/logo-black.svg";
import logoWhite from "../../assets/icons/logo-white.svg";
import ReactPlayer from "react-player";
import anime from "animejs";
import { Swiper, SwiperSlide } from "swiper/react";
// import {naviaget}

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, EffectCoverflow, Navigation } from "swiper";

import "./home.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import caseStudies from "../../assets/images/case-studies";
function Home() {
  const [isMobile, setIsMobile] = useState(false);

  // console.log(animationState);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    // document.body.style.zoom = "90%";
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // Optional smooth scrolling animation
    });
  };

  const [showHomePage, setshowHomePage] = useState(false);
  const [logoColor, setLogoColor] = useState(logoBlack);
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 786) {
      if (!sessionStorage.getItem("animation")) {
        anime({
          targets: ".imageContainer",
          scaleY: {
            value: [0, 1],
            duration: 600,
          },
          clipPath: {
            value: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            delay: 850,
            easing: "easeInOutSine",
            duration: 1,
          },
          width: [
            {
              value: "25vw",
              delay: 800,
              duration: 600,
              easing: "easeInOutQuint",
            },
          ],
          keyframes: [
            {
              top: "48%",
              duration: 1,
              delay: 0,
              easing: "easeInOutQuint",
            },

            {
              translateX: "-12.6vw",
              duration: 500,
              delay: 500,
              easing: "easeInOutQuint",
            },
            {
              top: "3%",
              duration: 1000,
              delay: 2000,
              easing: "easeInOutQuint",
              marginLeft: "2vw",
            },
          ],
        });

        anime({
          targets: ".animation-layer",
          // keyframes: [
          //   { backgroundColor: "#FFFFFF" },
          //   { backgroundColor: "#F2F2F2" },
          // ],
          easing: "easeInOutSine",
          duration: 800,
          // delay: 00,
          keyframes: [
            {
              clipPath: "polygon(35% 40%, 65% 40%, 65% 56%, 35% 56%)",
              delay: 850,
              duration: 800,
            },
            {
              clipPath: "polygon(0% 0%, 65% 38%, 65% 56%, 35% 56%)",
              duration: 180,
              delay: 250,
              easing: "easeInOutSine",
            },
            {
              clipPath: "polygon(0% 0%, 100% 0%, 65% 56%, 0% 100%)",
              duration: 180,
              easing: "easeInOutSine",
            },
            {
              clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
              duration: 180,
              easing: "easeInOutSine",
            },
          ],
        });

        anime({
          targets: ".animation-layer",
          keyframes: [{ backgroundColor: "#000000" }],
          delay: 1200,
          easing: "easeInOutQuad",
          duration: 1000,
        });

        anime({
          targets: ".animation-layer",
          keyframes: [],
          delay: 2500,
          easing: "easeInOutQuad",
          duration: 1500,
        });
        setTimeout(() => {
          setLogoColor(logoWhite);
        }, 1800);
        setTimeout(() => {
          setshowHomePage(true);
        }, 4000);
      } else {
        setTimeout(() => {
          setshowHomePage(true);
        }, 0);
      }
    } else {
      if (!sessionStorage.getItem("animation")) {
        anime({
          targets: ".imageContainer",
          scaleY: {
            value: [0, 1],
            duration: 600,
          },
          clipPath: {
            value: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            delay: 850,
            easing: "easeInOutSine",
            duration: 1,
          },
          width: [
            {
              value: "25vw",
              delay: 800,
              duration: 600,
              easing: "easeInOutQuint",
            },
          ],
          keyframes: [
            {
              translateX: "-12.6vw",
              duration: 500,
              delay: 500,
              easing: "easeInOutQuint",
            },
          ],
        });

        anime({
          targets: ".animation-layer",
          keyframes: [
            { backgroundColor: "#FFFFFF" },
            { backgroundColor: "#F2F2F2" },
          ],
          easing: "easeInOutSine",
          duration: 800,
          // delay: 00,
          keyframes: [
            {
              clipPath: "polygon(35% 40%, 65% 40%, 65% 56%, 35% 56%)",
              delay: 850,
              duration: 800,
            },
            {
              clipPath: "polygon(0% 0%, 65% 38%, 65% 56%, 35% 56%)",
              duration: 180,
              delay: 250,
              easing: "easeInOutSine",
            },
            {
              clipPath: "polygon(0% 0%, 100% 0%, 65% 56%, 0% 100%)",
              duration: 180,
              easing: "easeInOutSine",
            },
            {
              clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
              duration: 180,
              easing: "easeInOutSine",
            },
          ],
        });

        anime({
          targets: ".animation-layer",
          keyframes: [{ backgroundColor: "#000000" }],
          delay: 1200,
          easing: "easeInOutQuad",
          duration: 1000,
        });

        anime({
          targets: ".animation-layer",
          keyframes: [],
          delay: 2500,
          easing: "easeInOutQuad",
          duration: 1500,
        });

        setTimeout(() => {
          setLogoColor(logoWhite);
        }, 1800);

        // reveal of home page from here ....

        setTimeout(() => {
          setshowHomePage(true);
        }, 3000);
      } else {
        setshowHomePage(true);
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      anime({
        targets: ".Home-logo-animation",
        duration: 1250,
        easing: "easeInOutQuint",
        translateX: "2.2vw",
        marginTop: "-71.7vw",
        marginBottom: "100vw !important",
        translateY: [-window.innerHeight, -70],
        width: "30.9vw",
        // opacity : 0
      });
    } else if (window.innerWidth >= 1920) {
      anime({
        targets: ".Home-logo-animation",
        duration: 1250,
        easing: "easeInOutQuint",
        translateX: "-42.38vw",
        translateY: "-19.1vw",
        width: "8.4vw",
        // opacity : 0
      });
    } else {
      anime({
        targets: ".Home-logo-animation",
        duration: 1250,
        easing: "easeInOutQuint",
        translateX: "-40.5vw",
        translateY: "-17vw",
        width: "10.5vw",
        // opacity : 0
      });
    }

    anime({
      targets: ".animation-continuation-layer",
      width: "fit-content",
      height: "fit-content",
      delay: 1000,
    });

    setTimeout(() => {
      sessionStorage.setItem("animation", true);
    }, 3050);

    setTimeout(() => {
      setShowLogo(false);
    }, 4100);
    // }

    anime({
      targets: ".Header",
      opacity: [0, 0.5, 1],
      duration: 1000,
      easing: "easeInOutQuint",
    });
    // }
  }, [showHomePage]);

  return (
    <>
      {showHomePage && (
        <>
          <Navbar />
          <main className="Universal-Home">
            {/* {!sessionStorage.getItem("animation") && showLogo && (
              <section className="animation-continuation-layer">
                <img className="Home-logo-animation" src={logoWhite} alt="" />
              </section>
            )} */}
            {!sessionStorage.getItem("animation") && showLogo && (
              <section className="animation-continuation-layer">
                <img className="Home-logo-animation" src={logoWhite} alt="" />
              </section>
            )}
            <header className="Header">
              <section className="scroll-Content">
                <div
                  className="scroll-btn"
                  data-aos="fade-down"
                  data-aos-delay="300"
                  data-aos-easing="easein"
                >
                  <p>Scroll</p>
                  <img src={DownArrowWhite} alt="" />
                </div>
                <div className="content">
                  <p data-aos-easing="easein" data-aos="fade-up">
                    We are a creative marketing digital design agency that takes
                    a communicative approach to building communities. We are
                    passionate about designing ethical and meaningful
                    communications for brands. We go above and beyond
                    conventional strategies to deliver extraordinary results. We
                    believe that greatness lies beyond the confines of
                    traditional thinking, and we embrace the challenge of
                    exploring uncharted territories.
                  </p>
                </div>
              </section>
              <section className="bg-video">
                <div className="video-container">
                  <ReactPlayer
                    url={headerVideo}
                    playing={true}
                    controls={false}
                    muted={true}
                    width="100%"
                    height="100%"
                    loop={true}
                    playsinline
                    config={{
                      file: {
                        attributes: {
                          autoPlay: true,
                          muted: true,
                          controls: false,
                        },
                      },
                    }}
                  />
                  {/* <video
                    src={headerVideo}
                    autoPlay
                    muted
                    playsInline
                    loop
                    height="100%"
                    width="100%"
                  ></video> */}
                </div>
              </section>

              <section className="Content-Cards">
                <div
                  className="card"
                  data-aos-offset="200"
                  data-aos="fade-up"
                  data-aos-duration="200"
                  data-aos-easing="linear"
                >
                  <div className="col">
                    <h1 data-aos="fade-up">Growth Strategy</h1>
                  </div>
                  <div className="col">
                    <p
                      data-offset="300"
                      data-aos-easing="easein"
                      data-aos="fade-up"
                    >
                      By leveraging data-driven insights, optimizing customer
                      experiences, and fostering brand advocacy, we will help
                      drive awareness, attract new customers, and enhance
                      overall brand loyalty
                    </p>
                  </div>

                  <div className="arrow">
                    <img src={rightArrow} alt="error laoding" />
                  </div>
                </div>

                <div
                  className="card"
                  data-aos-offset="250"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                >
                  <div className="col">
                    <h1
                      data-aos-offset={isMobile ? "0" : "250"}
                      data-aos="fade-up"
                    >
                      Visual Identity{" "}
                    </h1>
                  </div>
                  <div className="col">
                    <p
                      data-offset="300"
                      data-aos-easing="easein"
                      data-aos="fade-up"
                    >
                      We strive to create visually compelling and consistent
                      brand experiences that resonate with audiences, strengthen
                      brand recognition, and drive business success. The brand’s
                      personality, positioning and message is give a true
                      definition.
                    </p>
                  </div>

                  <div className="arrow">
                    <img src={rightArrow} alt="error laoding" />
                  </div>
                </div>

                <div
                  className="card"
                  data-aos-offset="300"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-easing="linear"
                >
                  <div className="col">
                    <h1 data-aos="fade-up">Power of Design</h1>
                  </div>
                  <div className="col">
                    <p
                      data-offset="300"
                      data-aos-easing="easein"
                      data-aos="fade-up"
                    >
                      At Vaikhari, we redefine imagination. With a keen eye for
                      detail and a deep appreciation for aesthetics, we ensure
                      that every design element, colour palette, and visual
                      composition reflects the essence of our client's brand.
                    </p>
                  </div>
                  <div className="arrow">
                    <img src={rightArrow} alt="error laoding" />
                  </div>
                </div>
              </section>
            </header>

            <section className="image-slider-Brands">
              <div className="slider">
                <div className="container">
                  <h1 className="heading" data-aos="fade-up">
                    - Work
                  </h1>
                  <Swiper
                    effect={"coverflow"}
                    initialSlide={2}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    spaceBetween={isMobile ? 10 : -30}
                    slidesPerView={isMobile ? 1 : 2}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 100,
                      modifier: 2.5,
                      slideShadows: true,
                    }}
                    navigation={isMobile ? false : true}
                    modules={[Autoplay, EffectCoverflow, Navigation]}
                    className="swiper_container"
                    autoplay={{
                      delay: 2500,
                      // duration : 10000,
                      disableOnInteraction: false,
                    }}
                  >
                    <SwiperSlide
                      onClick={() => {
                        navigate(`/case-study`, {
                          state: { case : 'arkiton' },
                        });
                      }}
                    >
                      <div className="carosel-card">
                        <img
                          data-aos="zoom-in"
                          src={sliderArkiton}
                          alt="slide_image"
                        />
                        <div
                          className="card-content"
                          data-aos="zoom-in"
                          data-aos-easing="easein"
                        >
                          <h1>Arkiton</h1>
                          <p>Architecture and interior design agency</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide
                      onClick={() => {
                        navigate(`/case-study`, {
                          state: { case:'ennessar' },
                        });
                      }}
                    >
                      <div className="carosel-card">
                        <img
                          data-aos="zoom-in"
                          src={slideEnnerssar}
                          alt="slide_image"
                        />
                        <div
                          className="card-content"
                          data-aos="zoom-in"
                          data-aos-easing="easein"
                        >
                          <h1>Ennerssar</h1>
                          <p>Construction</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide
                      onClick={() => {
                        navigate(`/case-study`, {
                          state: { case : 'onlyhoney' },
                        });
                      }}
                    >
                      <div className="carosel-card">
                        <img
                          data-aos="zoom-in"
                          src={slideOnlyHoney}
                          alt="slide_image"
                        />
                        <div
                          className="card-content"
                          data-aos="zoom-in"
                          data-aos-easing="easein"
                        >
                          <h1>Only Honey</h1>
                          <p>FMCG</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide
                      onClick={() => {
                        navigate(`/case-study`, {
                          state: { case : 'ukusa' },
                        });
                      }}
                    >
                      <div className="carosel-card">
                        <img
                          data-aos="zoom-in"
                          src={slideUkusa}
                          alt="slide_image"
                        />
                        <div
                          className="card-content"
                          data-aos="zoom-in"
                          data-aos-easing="easein"
                        >
                          <h1>Ukusa</h1>
                          <p>F&B</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide
                      onClick={() => {
                        navigate(`/case-study`, {
                          state: { case : 'capnxt' },
                        });
                      }}
                    >
                      <div className="carosel-card">
                        <img
                          data-aos="zoom-in"
                          src={sliderCapnxt}
                          alt="slide_image"
                        />
                        <div
                          className="card-content"
                          data-aos="zoom-in"
                          data-aos-easing="easein"
                        >
                          <h1>CapNxt</h1>
                          <p>AI, UX, Software solutions</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    {/* <div className="slider-controler" data-offset="200">
                        <div className="swiper-button-prev slider-arrow">
                          <ion-icon
                            name="arrow-back-outline"
                            data-aos="fade-left"
                            data-aos-easing="easein"
                          ></ion-icon>
                        </div>
                        <div className="swiper-button-next slider-arrow">
                          <ion-icon
                            name="arrow-forward-outline"
                            data-aos="fade-right"
                            data-aos-easing="easein"
                          ></ion-icon>
                        </div>
                        <div className="swiper-pagination"></div>
                      </div> */}
                  </Swiper>
                </div>
              </div>
              <div className="Brands-quote">
                <div className="Quotation">
                  {/* <img className="marks" src={doubleQotation} alt="loading" /> */}
                  <p
                    data-offset="200"
                    data-aos-easing="easein"
                    data-aos="fade-up"
                  >
                    ”We understand that embracing the digital realm is a trend
                    and a necessity to remain competitive in today's market.
                    That's why we specialise in utilising the power of digital
                    to unlock growth potential for our clients.”
                  </p>
                  <div className="arrows">
                    <p data-aos="fade-left" data-offset="300">
                      {/* ← */}
                    </p>
                    <p data-aos="fade-right" data-offset="300">
                      {/* → */}
                    </p>
                  </div>
                </div>

                <div
                  className="Brand-card"
                  data-aos="zoom-in"
                  data-aos-offset="250"
                >
                  <h1>
                    <span
                      data-aos-easing="easein"
                      data-aos="fade-up"
                      data-aos-offset="310"
                    >
                      We harness the digital
                    </span>
                    <span
                      data-aos-easing="easein"
                      data-aos="fade-up"
                      data-aos-offset="320"
                    >
                      landscape’s immense power
                    </span>
                    <span
                      data-aos-easing="easein"
                      data-aos="fade-up"
                      data-aos-offset="321"
                    >
                      to propel our clients’ growth and success.
                    </span>
                  </h1>
                  <div
                    className="brands"
                    data-aos="fade-right"
                    data-aos-easing="easein"
                    data-offset="300"
                  >
                    <img src={Ennessar} alt="loading" />
                    <img className="spacer" src={FirstFleet} alt="loading" />
                    <img src={Arkiton} alt="loading" />
                  </div>
                </div>
              </div>
            </section>

            <section className="company-categories">
              <header className="header">
                <h1>
                  <li data-aos-easing="easein" data-aos="fade-up">
                    Industries we worked on.
                  </li>{" "}
                </h1>
                <p>
                  {/* <li data-aos-easing="easein" data-aos="fade-up">
                      {" "}
                      We pride ourselves on providing comprehensive services to a
                      broad
                    </li>{" "}
                    <li data-aos-easing="easein" data-aos="fade-up">
                      {" "}
                      range of industries. With our expertise and tailored strategies,
                    </li>{" "}
                    <li data-aos-easing="easein" data-aos="fade-up">
                      {" "}
                      we help businesses across various sectors maximize their online
                    </li>
                    <li data-aos-easing="easein" data-aos="fade-up">
                      {" "}
                      presence, reach their target audience, and achieve their
                      marketing
                    </li>{" "}
                    <li data-aos-easing="easein" data-aos="fade-up">
                      goals.
                    </li> */}
                  <li
                    data-aos-easing="easein"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    This multidimensional approach ensures that your digital
                    marketing campaigns are designed and executed with
                    unparalleled precision, creativity, and effectiveness. Take
                    a look at all the industries we’ve had the opportunity to
                    work with.
                  </li>
                </p>
              </header>

              <div className="categories-cards">
                <div className="row1">
                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="400"
                  >
                    <img src={CafeAndProducts} alt="" />
                    <p>cafe & Products</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  >
                    <img src={construction} alt="" />
                    <p>Construction</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                  >
                    <img src={RealState} alt="" />
                    <p>Real State</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={Education} alt="" />
                    <p>Education</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                  >
                    <img src={Technology} alt="" />
                    <p>Technology</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="1400"
                  >
                    <img src={Textile} alt="" />
                    <p>Textile</p>
                  </div>
                </div>
                <div className="row2">
                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="400"
                  >
                    <img src={HealthCare} alt="error" />
                    <p>Health Care</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  >
                    <img src={Transport} alt="error" />
                    <p>Transport</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                  >
                    <img src={lifeStyle} alt="error" />
                    <p>Life Style</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={professionalService} alt="error" />
                    <p>Professional Services</p>
                  </div>

                  <div
                    className="card"
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                  >
                    <img src={Trading} alt="error" />
                    <p>Trading</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="Get-In-Touch">
              <header>
                <p data-aos="fade-up">Think you got a story to be told?</p>
                <p data-aos="fade-up">Let us help you with that. </p>
                <button onClick={scrollToBottom} data-aos="fade-up">
                  Get in touch
                </button>
              </header>
              <div className="map" data-aos="fade-up">
                <div style={{ width: "100%" }}>
                  <iframe
                    width="100%"
                    height="450"
                    title="home-map"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20Vaikhari%20Floor,%2095,%20Lumbini%20Avenue,%20Gachibowli,%20Hyderabad,%20Telangana%20500032+(vaikhari)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}

      {!sessionStorage.getItem("animation") && (
        <>
          <section className="animation-container">
            <div className="animation-layer">
              <div className="imageContainer">
                <img src={logoColor} className="image" alt="error loadin" />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Home;

import "./services.scss";
import DownArrowBlack from "../../assets/icons/down-arrow-black.svg";
import HeadVideo from "../../assets/videos/service-head.mp4";
import RightArrow from "../../assets/icons/right-arrow.svg";
import ArkitonLogo from "../../assets/images/arkiton-logo.png";
import OnlyHoneyLogo from "../../assets/images/only-honey-sticker-logo.png";
import EnergonLogo from "../../assets/images/energon-logo.png";
import EnnessarLogo from "../../assets/images/ennessar-logo.png";
import FirstFleetLogo from "../../assets/images/first-fleet-logo.png";
import CapNxtLogo from "../../assets/images/capnxt-logo.png";
import PrakrutiAvenuesLogo from "../../assets/images/prakruti-avenues- logo.png";
import PrakrutiSchoolLogo from "../../assets/images/prakruti-school-logo.png";
import TriveniLogo from "../../assets/images/triveni-logo.png";
import UkusaLogo from "../../assets/images/ukusa-logo.png";
import StockerLogo from "../../assets/images/stocker-logo.png";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
export default function Services() {
  const Logos = [
    EnnessarLogo,
    FirstFleetLogo,
    ArkitonLogo,
    PrakrutiAvenuesLogo,
    TriveniLogo,
    EnergonLogo,
    PrakrutiSchoolLogo,
    CapNxtLogo,
    StockerLogo,
    OnlyHoneyLogo,
    UkusaLogo,
  ];
  const serviceCardActive = (cardId) => {
    // expand service card
    console.log(cardId);
    const card = document.querySelector(`#${cardId}`);
    card.classList.toggle("active");
  };
  useEffect(() => {
    // Adding scroll event listener
    document.addEventListener("scroll", horizontalScroll);

    //Selecting Elements
    let sticky = document.querySelector(".sticky");
    let stickyParent = document.querySelector(".sticky-parent");

    let scrollWidth = sticky.scrollWidth;
    let verticalScrollHeight =
      stickyParent.getBoundingClientRect().height -
      sticky.getBoundingClientRect().height;

    //Scroll function
    function horizontalScroll() {
      //Checking whether the sticky element has entered into view or not
      let stickyPosition = sticky.getBoundingClientRect().top;
      if (stickyPosition > 1) {
        return;
      } else {
        let scrolled = stickyParent.getBoundingClientRect().top; //how much is scrolled?
        sticky.scrollLeft =
          (scrollWidth / verticalScrollHeight) * -scrolled * 0.85;
      }
    }
  }, []);
  return (
    <>
      <div className="services">
        <Navbar />
        <div className="br">
          <div className="head">
            <div
              className="scroll-btn"
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>Scroll</p>

              <img src={DownArrowBlack} alt="" />
            </div>
            <div className="show-video">
              <p data-aos="fade-down">Get to know a bit <br /> more about us</p>
              <video
                src={HeadVideo}
                autoPlay
                muted
                playsInline
                loop
                data-aos="fade-zoom-in"
                data-aos-delay="300"
                data-aos-easing="easein"
                data-aos-duration="1000"
              ></video>
            </div>
          </div>
        </div>
        <div className="sticky-parent">
          <div className="sticky">
            <div className="horizontal">
              <div className="dim">
                <div className="description">
                  <div
                    className="title"
                    data-aos="fade-up"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                  >
                    <p>Brands are human stories. Designs are brand stories.</p>
                  </div>
                  <div
                    className="desc"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                  >
                    <p>
                      Our team of brand strategists combine business, marketing,
                      communications, and design thinking to develop meaningful,
                      differentiated, and authentic brands for companies big and
                      small, start-ups. We don't just come up with what’s cool;
                      we craft the message your customers want to hear and one
                      that you can deliver. We’ve seen firsthand how the power
                      of a brand can elevate a company from a commodity to a
                      valued partner; how it can rescue a business from the
                      trenches of an impossible-to -win price war, and how it
                      can motivate employees and customers alike to become full-
                      fledged fans.
                    </p>
                  </div>
                </div>
              </div>
              <div className="dim desktop">
                <div className="service-catlouge">
                  <div
                    className="service-card"
                    data-aos="zoom-in-left"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    data-aos-anchor-placementr="top-left"
                  >
                    <div
                      className="card-title"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        Digital <br /> Marketing
                      </p>
                    </div>
                    <div
                      className="card-desc"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        We help our clients build strong brand awareness, foster
                        meaningful connections, and drive traffic to their
                        websites by crafting compelling content and implementing
                        targeted advertising campaigns. We help you harness the
                        power of digital media.
                      </p>
                    </div>
                    <div className="card-footer">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                  <div
                    className="service-card"
                    data-aos="zoom-in-left"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    data-aos-anchor-placementr="top-left"
                  >
                    <div
                      className="card-title"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        Graphic <br /> Designing{" "}
                      </p>
                    </div>
                    <div
                      className="card-desc"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        Graphic design is the art of visually communicating your
                        brand's message. Our talented designers use colours,
                        typography, and imagery to create stunning visuals that
                        enhance your brand identity and engage your audience,
                        helping you make a lasting impact in the digital world
                      </p>
                    </div>
                    <div className="card-footer">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                  <div
                    className="service-card"
                    data-aos="zoom-in-left"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    data-aos-anchor-placementr="top-left"
                  >
                    <div
                      className="card-title"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>Website Design & Development</p>
                    </div>
                    <div
                      className="card-desc"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        By creating the most appealing and easy-onthe-eye
                        designs, we ensure your audience enjoys seamless
                        navigation, engaging interactions, and a delightful
                        overall experience, resulting in higher customer
                        satisfaction and increased conversion rates
                        <br />
                      </p>
                    </div>
                    <div className="card-footer">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                  <div
                    className="service-card"
                    data-aos="zoom-in-left"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    data-aos-anchor-placementr="top-left"
                  >
                    <div
                      className="card-title"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>Branding</p>
                    </div>
                    <div
                      className="card-desc"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-easing="easein"
                      data-aos-duration="1000"
                    >
                      <p>
                        At Vaikhari, we specialize in digital marketing
                        solutions that power your brand's success. With a deep
                        understanding of the digital landscape, we craft
                        strategic branding initiatives that captivate audiences
                        and drive growth. Trust us to elevate your brand and
                        make a lasting impact in the digital world.
                        <br />
                      </p>
                    </div>
                    <div className="card-footer">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="description mobile">
          <div
            className="title"
            data-aos="fade-up"
            data-aos-easing="easein"
            data-aos-duration="1000"
          >
            <p>Brands are human stories. Designs are brand stories.</p>
          </div>
          <div
            className="desc"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-easing="easein"
            data-aos-duration="1000"
          >
            <p>
              We love what we do for a living, and we love hearing feedback
              about it. There is nothing better than sending back looking at a
              completed pool with our customers, admiring the end result.
            </p>
          </div>
        </div>
        <div className="service-catlouge mobile">
          <div
            className="service-card"
            data-aos="zoom-in-left"
            data-aos-easing="easein"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-anchor-placementr="top-left"
            id="card-1"
            onClick={() => {
              serviceCardActive("card-1");
            }}
          >
            <div
              className="card-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                Digital <br />
                Marketing
              </p>
            </div>
            <div
              className="card-desc"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                Graphic design is the art of visually communicating your brand's
                message. Our talented designers use colours, typography, and
                imagery to create stunning visuals that enhance your brand
                identity and engage your audience, helping you make a lasting
                impact in the digital world.
              </p>
            </div>
            <div className="card-footer">
              <img src={RightArrow} alt="" />
            </div>
          </div>
          <div
            className="service-card"
            data-aos="zoom-in-left"
            data-aos-easing="easein"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-anchor-placementr="top-left"
            id="card-2"
            onClick={() => {
              serviceCardActive("card-2");
            }}
          >
            <div
              className="card-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>Website Design & Development</p>
            </div>
            <div
              className="card-desc"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                By creating the most appealing and easy-onthe-eye designs, we
                ensure your audience enjoys seamless navigation, engaging
                interactions, and a delightful overall experience, resulting in
                higher customer satisfaction and increased conversion rates.
              </p>
            </div>
            <div className="card-footer">
              <img src={RightArrow} alt="" />
            </div>
          </div>
          <div
            className="service-card"
            data-aos="zoom-in-left"
            data-aos-easing="easein"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-anchor-placementr="top-left"
            id="card-3"
            onClick={() => {
              serviceCardActive("card-3");
            }}
          >
            <div
              className="card-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>Branding</p>
            </div>
            <div
              className="card-desc"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                At Vaikhari, we specialize in digital marketing solutions that
                power your brand's success. With a deep understanding of the
                digital landscape, we craft strategic branding initiatives that
                captivate audiences and drive growth. Trust us to elevate your
                brand and make a lasting impact in the digital world.
              </p>
            </div>
            <div className="card-footer">
              <img src={RightArrow} alt="" />
            </div>
          </div>
          <div
            className="service-card"
            data-aos="zoom-in-left"
            data-aos-easing="easein"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-anchor-placementr="top-left"
            id="card-4"
            onClick={() => {
              serviceCardActive("card-4");
            }}
          >
            <div
              className="card-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>Brand & Business Strategy</p>
            </div>
            <div
              className="card-desc"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                We enable our clients to stand out, engage their target
                audience, and accomplish their company goals through our
                thorough branding and business planning services.
                <br />
              </p>
            </div>
            <div className="card-footer">
              <img src={RightArrow} alt="" />
            </div>
          </div>
        </div>
        <div style={{ width: "100vw" }}>
          <div className="clients">
            <div className="title">
              <h1 data-aos="fade-up">A clientele covering the whole spectrum</h1>
              <div className="desc" data-aos="fade-up">
                <p>
                  At Vaikhari, we understand that a strong brand identity is
                  essential to building a successful business. We work closely
                  with you to develop a compelling brand identity that resonates
                  with your target audience.
                </p>
              </div>
            </div>

            <div className="client-logos">
              {Logos.map((logo, i) => {
                var aosAction = "fade-left";
                if (i > Logos.length / 2) {
                  aosAction = "fade-right";
                }
                return (
                  <img
                    src={logo}
                    alt="client-logo"
                    key={`logo - ${i}`}
                    className="client-logo"
                    data-aos={aosAction}
                    data-aos-delay="400"
                    data-aos-easing="easein"
                    data-aos-duration="1000"
                  />
                );
              })}
            </div>
          </div>
          <div className="shout">
            <div className="ribbon"></div>
            <div className="card">
              <p>
                Do you have a start-up or a plan to expand your existing
                business? Call us!
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

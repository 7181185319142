import React, { useState } from "react";
import "../our-work/work.scss";
import "aos/dist/aos.css";
import ourwork from "../../assets/images/our-work-1.jpg";
import showreel from "../../assets/videos/Showreel.mp4";
import ourwork1 from "../../assets/images/our-work-2.jpg";
import ourwork2 from "../../assets/images/our-work-3.jpg";
import DownArrowWhite from "../../assets/icons/down-arrow-white.svg";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import ArkitonLogo from "../../assets/images/arkiton-logo.png";
import OnlyHoneyLogo from "../../assets/images/only-honey-sticker-logo.png";
import EnergonLogo from "../../assets/images/energon-logo.png";
import EnnessarLogo from "../../assets/images/ennessar-logo.png";
import FirstFleetLogo from "../../assets/images/first-fleet-logo.png";
import CapNxtLogo from "../../assets/images/capnxt-logo.png";
import PrakrutiAvenuesLogo from "../../assets/images/prakruti-avenues- logo.png";
import PrakrutiSchoolLogo from "../../assets/images/prakruti-school-logo.png";
import TriveniLogo from "../../assets/images/triveni-logo.png";
import UkusaLogo from "../../assets/images/ukusa-logo.png";
import StockerLogo from "../../assets/images/stocker-logo.png";
import VaikhariLogo from "../../assets/images/vaikhari-logo.png";
import IndianSilkGalleryLogo from '../../assets/images/isg-logo.png';

import { useNavigate } from "react-router-dom";
export default function Work() {
  const navigate = useNavigate();
  const [currentCase, setCurrentCase] = useState({
    img: VaikhariLogo,
    case: "vaikhari",
  });
  const brands = [
    {
      img: EnnessarLogo,
      case: "ennessar",
    },
    {
      img: FirstFleetLogo,
      case: "firstfleet",
    },
    {
      img: ArkitonLogo,
      case: "arkiton",
    },

    {
      img: PrakrutiAvenuesLogo,
      case: "prakrutiavenues",
    },
    {
      img: TriveniLogo,
      case: "triveni",
    },
    {
      img: EnergonLogo,
      case: "energon",
    },
    {
      img: PrakrutiSchoolLogo,
      case: "prakrutischool",
    },
    {
      img: CapNxtLogo,
      case: "capnxt",
    },
    {
      img: StockerLogo,
      case: "stocker",
    },
    {
      img: UkusaLogo,
      case: "ukusa",
    },
    {
      img: OnlyHoneyLogo,
      case: "onlyhoney",
    },
    {
      img : IndianSilkGalleryLogo,
      case : "isg"
    }
  ];
  return (
    <>
      <Navbar />
      <section className="our-work">
        <div className="head">
          <div
            className="scroll-btn"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-easing="easein"
            data-aos-duration="1000"
          >
            <p>Scroll</p>
            <img src={DownArrowWhite} alt="" />
          </div>
          <div className="description">
            <div className="title">
              <h1
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-easing="easein"
                data-aos-duration="1000"
              >
                Our Work
              </h1>
              <div className="body">
                <div className="image">
                  <img src={ourwork} alt="error loaidng" />
                </div>
                <div
                  className="desc"
                  data-aos="fade-up"
                  data-aos-easing="easein"
                  data-aos-duration="1000"
                >
                  <h1>
                    Our creativity <br /> knows no boundaries
                  </h1>
                  <p>
                    <span>
                      We believe that to make a lasting impact, you need
                      fearless
                    </span>
                    <span>
                      thinking, bold creativity, and high-performance marketing
                    </span>
                    <span>
                      that will propel your brand forward. We understand that
                    </span>
                    <span>
                      embracing the digital realm is a trend and a necessity to
                    </span>
                    <span>
                      remain competitive in today's market. That's why we
                    </span>
                    <span>
                      specialise in utilising the power of digital to unlock
                    </span>
                    <span>growth potential for our clients.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="showreel">
          <video src={showreel} autoPlay muted loop playsInline></video>
        </div>
        <div className="our-love">
          <p
          // data-aos="fade-up"
          // data-aos-easing="easein"
          >
            We don't settle for the ordinary; we strive for excellence in every
            pixel.
          </p>
        </div>
        <div className="compassion">
          <div className="image">
            <img src={ourwork1} alt="" />
          </div>
          <div className="description">
            <div className="image">
              <img src={ourwork2} alt="" />
            </div>
            <div
              className="desc"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-easing="easein"
              data-aos-duration="1000"
            >
              <p>
                <span>Through strategic planning and implementation,</span>{" "}
                <span>
                  we breathe life into your brand by creating a lasting
                </span>{" "}
                <span>brand identity and ensuring we tell your brand’s</span>{" "}
                <span>story in the most impactful approach.</span>
              </p>
            </div>
          </div>
        </div>
        <div className="case-studies">
          <div className="case-head">
            <div className="col title">
              <p>Explore Our</p>
              <p>Brand Identity</p>
              <p>Case Study</p>
            </div>
            <div
              className="col preview"
              onClick={() => {
                navigate(`/case-study`, {
                  state: { ...currentCase },
                });
              }}
            >
              <img src={currentCase.img} alt="" />
            </div>
          </div>
          <div className="logos">
            {brands.map((rec, i) => {
              return (
                <div
                  className="logo"
                  key={`image ${i}`}
                  onMouseEnter={() => {
                    setCurrentCase(rec);
                  }}
                  onClick={() => {
                    navigate(`/case-study?${rec.case}`, { state: { ...rec } });
                  }}
                >
                  <img src={rec.img} alt="" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="shout">
          <div className="ribbon"></div>
          <div className="card">
            <p>
              Do you have a start-up or a plan to expand <br /> your existing
              business? Call us!
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

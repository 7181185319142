import BlackLogo from "../../assets/icons/logo-black.svg";
import "./footer.scss";
import WhiteLogo from "../../assets/icons/logo-white.svg";
import fbIcon from "../../assets/icons/fb.svg";
import igIcon from "../../assets/icons/ig.svg";
import ytIcon from "../../assets/icons/yt.svg";
import inIcon from "../../assets/icons/in.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import emailjs from "@emailjs/browser";
export default function Footer() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    firstName: "",
    lastName: "",
    message: "",
    email: "",
  });
  const [formError, setFormError] = useState(null);
  const sendForm = (device = "desktop") => {
    const sendBtns = document.querySelectorAll(`.footer .send-btn p`);
    sendBtns[0].textContent = "sending...";
    sendBtns[1].textContent = "sending...";
    // send form data
    // validate form data
    var phoneno = /^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    if (formData.name.length === 0 && device === "desktop") {
      setFormError("name cannot be empty");
      return;
    }
    if (formData.phone.length === 0) {
      setFormError("phone cannot be empty");
      return;
    } else {
      if (formData.phone.match(phoneno) || formData.phone.length < 10) {
        setFormError("invalid phone number");
        return;
      }
    }
    if (device === "mobile") {
      if (formData.firstName.length === 0) {
        setFormError("name cannot be empty");
        return;
      }
      if (formData.email.length === 0) {
        setFormError("email cannot be empty");
        return;
      }
    }
    setFormError(null);
    console.log(formData);
    // send email
    emailjs
      .send(
        "service_wn3jm5m",
        "template_0f5v2po",
        formData,
        "9ZYF96yYDWTPzU31M"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          sendBtns[1].textContent = "sent";
          sendBtns[0].textContent = "sent";
          setFormData({
            name: "",
            phone: "",
            firstName: "",
            lastName: "",
            message: "",
            email: "",
          });
        },
        (err) => {
          setFormError("Error while sending the message!. Try again");
          sendBtns[1].textContent = "send";
          sendBtns[0].textContent = "send";
        }
      );
  };
  return (
    <>
      <div className="footer desktop">
        <div className="logo">
          <Link to="/">
            <img src={BlackLogo} alt="logo" />
          </Link>
        </div>
        <div className="body">
          <div className="column" data-aos="fade-up">
            <ul>
              <li className="title">We are here</li>
              <li>
                <a
                  href="https://goo.gl/maps/t5pFsx35DRdk2qD99?coh=178571&entry=tt"
                  target="_blank"
                  rel="noreferrer"
                >
                  3rd Floor, 95,Lumbini Avenue, Gachibowli, Hyderabad, Telangana
                  500032
                </a>
              </li>
            </ul>
          </div>
          <div className="column" data-aos="fade-up">
            <ul>
              <li className="title">Get in touch</li>
              <li>
                <a href="tel:918803755555">+91 88037 55555 </a>
              </li>
              <li>
                <a href="mailto:hello@vaikharidigital.com">
                  hello@vaikharidigital.com
                </a>
              </li>
              <li className="social-icons">
                <div className="social-icon">
                  <a
                    href="https://www.facebook.com/vaikharidigital/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fbIcon} alt="" />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.instagram.com/vaikharidigital/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={igIcon} alt="" />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.linkedin.com/company/vaikhari/?originalSubdomain=in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={inIcon} alt="" />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.youtube.com/channel/UCtqaLcRwrt6jrD4pYj35UKg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ytIcon} alt="" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="column" data-aos="fade-up">
            <ul>
              <li className="title">Useful Links</li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/work">Our Work</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
            </ul>
          </div>
          <div className="column" data-aos="fade-up">
            <ul>
              <li className="title">Request a callback</li>
              <li>
                <div className="form">
                  <div className="name input-element">
                    <input
                      type="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        });
                      }}
                      placeholder="Enter your name..."
                    />
                  </div>
                  <div className="name input-element">
                    <input
                      type="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        });
                      }}
                      placeholder="Enter your email..."
                    />
                  </div>
                  <div className="tel input-element">
                    <input
                      type="tel"
                      value={formData.phone}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          phone: e.target.value,
                        });
                      }}
                      placeholder="Enter your phone..."
                    />
                  </div>
                  <p className="error">{formError ? `*${formError}` : ""}</p>
                  <div
                    className="submit input-element send-btn"
                    onClick={sendForm}
                  >
                    <p>Send</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          &copy; {new Date().getFullYear()} | All Rights Reserved | Vaikhari Digital 
        </div>
      </div>
      <div className="footer mobile">
        <div className="contact-form">
          <div className="title">
            <p>Get in touch with us</p>
          </div>
          <div className="body">
            <div className="form">
              <div className="field-row">
                <div className="field-container">
                  <label htmlFor="last-name">First Name</label>
                  <div className="input-container">
                    <input
                      type="text"
                      id="last-name"
                      placeholder="Last name"
                      value={formData.firstName}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          firstName: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="field-container">
                  <label htmlFor="first-name">Last Name</label>
                  <div className="input-container">
                    <input
                      type="text"
                      id="first-name"
                      placeholder="First name"
                      value={formData.lastName}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="field-row">
                <div className="field-container">
                  <label htmlFor="email">Email</label>
                  <div className="input-container">
                    <input
                      type="text"
                      id="email"
                      placeholder="E-mail"
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="field-container">
                  <label htmlFor="mobile">Mobile</label>
                  <div className="input-container">
                    <input
                      type="text"
                      id="mobile"
                      placeholder="Mobile"
                      value={formData.phone}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          phone: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="field-row">
                <div className="field-container">
                  <label htmlFor="message">Message</label>
                  <div className="input-container">
                    <textarea
                      name=""
                      id="message"
                      placeholder="message..."
                      value={formData.message}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          message: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <p className="error">{formError ? `*${formError}` : ""}</p>
              <div className="field-row">
                <div
                  className="field-container submit send-btn"
                  onClick={() => {
                    sendForm("mobile");
                  }}
                >
                  <p>Send</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map">
          <div className="mapouter">
            <div className="gmap_canvas">
              {/* new address shows vaikhari.. */}
              <iframe
                width="100%"
                height="100%"
                title="foo"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20Vaikhari%20Floor,%2095,%20Lumbini%20Avenue,%20Gachibowli,%20Hyderabad,%20Telangana%20500032+(vaikhari)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
          {/* Old Maps uncommented for showing capnxt address.. */}
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5743134918284!2d78.3736038!3d17.4322049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb933f1e2cb16d%3A0xa414c4b9f1c26790!2sCAPNXT%20GLOBAL%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1684400405691!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            title="map"
          ></iframe> */}
        </div>
        <div className="bottom-bar">
          <div className="logo">
            <img src={WhiteLogo} alt="" />
          </div>
          <div className="contact">
            <p>
              <a href="mailto:hello@vaikharidigital.com">
                hello@vaikharidigital.com
              </a>
            </p>
            <p>
              <a href="tel:+918803755555">+91 88037 555555</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
